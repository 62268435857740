class EVENTS {
}

EVENTS.ON_SEARCH = 'on-search'

export default {
    props: {
        geocoderId: String,
        label: String,
        zindex: String,
    },
    emits: Object.values(EVENTS),
    watch: {},
    data() {
        return {
            geocoder: null,
        };
    }
    ,
    methods: {
        updateWaypoint(event) {
            const {result} = event
            if (result && result?.center) {
                this.$emit(EVENTS.ON_SEARCH, result)
            }
        },
        setupGeocoder() {
            mapboxgl.accessToken = this.$root.MAPBOX_API
            this.geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                placeholder: this.label,
                countries: 'no,se,dk,fi',
            });

            document.getElementById(this.geocoderId).appendChild(this.geocoder.onAdd());

            this.geocoder.on('result', (result) => {
                this.updateWaypoint(result)
            })
        },
    },
    mounted() {
        this.setupGeocoder()
    },
    template: `
        <div class="mapboxgl-ctrl-geocoder-container">
            <label>[[[label]]]</label>
            <div :style="{ 'z-index': zindex }" :id='geocoderId' class='geocoder'></div>
        </div>
    `,
}
;
