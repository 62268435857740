import NMapboxPopup from "./n-mapbox-popup.js";
import NButton from "../buttons/n-button.js";

class EVENTS {
}

const ZOOM_LEVEL_DEFAULT = 15

export default {
    inject: ['toaster'],
    props: {
        isLoading: Boolean,
        placesData: Object,
        center: Array,
    },
    emits: Object.values(EVENTS),
    components:
        {
            'n-mapbox-popup': NMapboxPopup,
            'n-button': NButton,
        },
    watch: {
        placesData: {
            handler(newValue) {
                this.loadPlaces()
            },
            deep: true,
        },
        center: {
            handler(newValue) {
                this.map.jumpTo({center: newValue})
            },
            deep: true,
        },
        isLoading: {
            handler(newValue) {
                if (newValue) {
                    this.runLoadingAnimation = newValue
                }
            }
        }
    }
    ,
    data() {
        return {
            map: null,
            zoom: ZOOM_LEVEL_DEFAULT,
            popup: null,
            runLoadingAnimation: false,
        };
    },
    methods: {
        setupMap(mapCenter) {
            if (!this.map) {
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v12',
                    center: mapCenter,
                    zoom: this.zoom
                });

                this.map.once('load', () => {
                    this.popup = new mapboxgl.Popup({
                        className: 'n-map-popup'
                    });
                    this.addMapEvents()
                    this.map.resize()
                    setTimeout(() => {
                        this.runLoadingAnimation = false
                    }, 200)
                })

            }
        },
        addMarkerLayers() {
            // Selected icon layer
            this.map.addLayer({
                id: this.placesData.layerID,
                type: 'symbol',
                source: this.placesData.layerID,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': ['get', 'icon'],
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },

            });
        },
        loadPlaces() {
            this.loadImages().then(() => {
                this.map.addSource(this.placesData.layerID, {
                    type: 'geojson',
                    data: this.placesData.data,
                });
                this.addMarkerLayers();
            })
        },
        loadImages() {
            return new Promise((resolve, reject) => {
                this.map.loadImage(FACILITY_MARKER_SELECTED.url, (error, img) => {
                    if (error) {
                        reject(error);
                        return
                    }
                    this.map.addImage(FACILITY_MARKER_SELECTED.name, img);
                    resolve();
                });
            });
        },
        addMapEvents() {
            this.map.on('mouseenter', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });
            this.map.on('touchstart', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });

            this.map.on('mouseleave', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove()
            });
            this.map.on('touchend', this.placesData.layerID, () => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
        },
        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                } else {
                    reject(new Error('Geolocation is not supported by this browser.'));
                }
            });
        },
        handlePlaceHover(e) {
            this.addPopup(feature, coordinates)
            // Add handleRoute here when routing is ready
        },
        addPopup(e) {
            const PRESSED_ICON_INDEX = 0
            const feature = e.features[PRESSED_ICON_INDEX];
            const lng = e.lngLat.lng;

            const coordinates = feature.geometry.coordinates.slice();

            this.calculateCoordinates(coordinates, lng);

            const props = feature.properties;
            const popupContent = document.createElement('div');

            const tempApp = Vue.createApp({
                render() {
                    return Vue.h(NMapboxPopup, {...props});
                }
            });

            tempApp.component('n-mapbox-popup', NMapboxPopup);
            tempApp.config.compilerOptions.delimiters = ["[[[", "]]]"]
            tempApp.mount(popupContent);

            this.popup
                .setLngLat(coordinates)
                .setDOMContent(popupContent)
                .setOffset([-3, -20])
                .addTo(this.map);
        },
        calculateCoordinates(coordinates, lng) {
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            const PRESSED_ICON_INDEX = 0
            const DEGREES_ONE_EIGHTY = 180
            const DEGREES_THREE_SIXTY = 360
            const currentLng = coordinates[PRESSED_ICON_INDEX];
            if (Math.abs(lng - currentLng) > DEGREES_ONE_EIGHTY) {
                coordinates[PRESSED_ICON_INDEX] += lng > currentLng ? DEGREES_THREE_SIXTY : -DEGREES_THREE_SIXTY;
            }
        },
    },
    mounted() {
        mapboxgl.accessToken = this.$root.MAPBOX_API;
        this.setupMap([18.643501, 60.128161])
    },
    template: `

    <div class="n-flex n-col gap-m" style="padding-top: 16px; flex: 1; min-height:300px; min-width:300px">
        <div id='map' style='width: 100%; height: auto; flex: 1; border-radius: 5px; border: 2px solid #55b8ff'>
            <div v-if="runLoadingAnimation" class="n-skeleton">
            <span class="n-loader" ></span>
            </div>
        </div>
    </div>
    `,
}
;
