import NButton from '../buttons/n-button.js'
import NCheckbox from '../inputs/n-checkbox.js'
import NMapboxGeocoder from './n-mapbox-geocoder.js'
import NDateInput from '../inputs/n-date-input.js'
import NMapboxCreateRoutePopup from './n-mapbox-create-route-popup.js'
import NDialog from '../n-dialog.js'


class ROUTING_PROFILES {
}

ROUTING_PROFILES.DRIVING_TRAFFIC = 'mapbox/driving-traffic'
ROUTING_PROFILES.DRIVING = 'mapbox/driving'
ROUTING_PROFILES.CYCLING = 'mapbox/cycling'
ROUTING_PROFILES.WALKING = 'mapbox/walking'

const ZOOM_LEVEL_DEFAULT = 5
const ZOOM_LEVEL_CLOSE = 8

class RADIUS {
}

RADIUS.SMALL = 20
RADIUS.MEDIUM = 30
RADIUS.LARGE = 40

class THRESHOLD {
}

THRESHOLD.FIRST = 10
THRESHOLD.SECOND = 50

class EVENTS {
}

EVENTS.SELECT_LOCATION = 'on-select-location'
EVENTS.ADD_LOCATION = 'on-add-location'
EVENTS.ON_SEARCH = 'on-search'
EVENTS.STARTMARKER_DRAG = 'start-drag'
EVENTS.ENDMARKER_DRAG = 'end-drag'
EVENTS.SELECT_AUDIT = 'on-select-audit'
EVENTS.UNSELECT_AUDIT = 'on-unselect-audit'
EVENTS.MAP_LOADED = 'on-map-loaded'

const MAPBOX_CLUSTER_PAINT_STYLE = {
    'circle-color':
        [
            'step',
            ['get', 'point_count'],
            '#19A08C',
            THRESHOLD.FIRST,
            '#28C882',
            THRESHOLD.SECOND,
            '#A0FFBE'
        ],
    'circle-radius': [
        'step',
        ['get', 'point_count'],
        RADIUS.SMALL,
        THRESHOLD.FIRST,
        RADIUS.MEDIUM,
        THRESHOLD.SECOND,
        RADIUS.LARGE,
    ],
}

const MAPBOX_CLUSTER_TEXT_LAYOUT = {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 16
}
const MAPBOX_CLUSTER_TEXT_PAINT = {
    'text-color': '#ffffff',
    'text-halo-color': '#000000',
    'text-halo-width': 1
}

export default {
    inject: ['toaster'],
    props: {
        isLoading: Boolean,
        placesData: Object,
        isDisabled: {
            default:
                false,
            type:
            Boolean,
        },
        startWaypoint: Object,
        endWaypoint: Object,
        center: Array,
        routeGeoJson: Object,
        selectedLocations: Array,
    },
    emits: Object.values(EVENTS),
    components:
        {
            'n-dialog': NDialog,
            'n-date-input': NDateInput,
            'n-mapbox-create-route-popup': NMapboxCreateRoutePopup,
            'n-button': NButton,
            'n-checkbox': NCheckbox,
            'n-mapbox-geocoder': NMapboxGeocoder,
        }
    ,
    watch: {
        startWaypoint: {
            handler(waypoint) {
                const {center} = waypoint

                if (this.map && center) {
                    this.startMarker.setLngLat(center).addTo(this.map)
                }
            },
            deep: true
        },
        placesData: {
            handler(placesData) {
                //TODO Rework this and see if we can manage without watching
                // placesData and selectedLocations are tightly coupled and must be ran in a specific order
                if (this.map && !this.mapInitiated) {
                    this.popup = new mapboxgl.Popup({
                        className: 'n-map-popup',
                        closeButton: false, offset: 25
                    })
                    this.loadPlaces()
                    this.map.resize()
                    this.addMapEvents()
                    this.mapInitiated = true
                }
            },
            deep: true
        },
        selectedLocations: {
            handler(selectedLocations) {
                for (let i = 0; i < this.selectedMarkers.length; ++i) {
                    this.unselectMarker(this.selectedMarkers[i])
                }
                this.updateSelectedMarkers(selectedLocations)
            },
        },
        endWaypoint: {
            handler(waypoint) {
                const {center} = waypoint
                if (this.map && center) {
                    this.endMarker.setLngLat(center).addTo(this.map)
                }
            },
            deep: true
        },
        routeGeoJson: {
            handler(routeGeoJson) {
                this.setRoute()
            },
            deep: true
        },
    }
    ,
    data() {
        return {
            map: null,
            directions: null,
            selectedMarkers: [],
            routeData: {},
            zoom: ZOOM_LEVEL_DEFAULT,
            stops: [],
            popup: null,
            mapboxSearchField: null,
            searchMarker: null,
            startMarker: null,
            endMarker: null,
            FACILITY_PLAN_STATES: FACILITY_PLAN_STATES,
            geocoderFrom: null,
            geocoderTo: null,
            filters: {
                'planState': [],
                'deadline_date': {
                    'fromDate': null,
                    'toDate': null,
                },
            },
            fromDateValue: null,
            toDateValue: null,
            showAuditsDialog: false,
            currentFacility: {},
            auditAlreadyPlannedText: gettext('Audit is planned for another route'),

            mapInitiated: false,
        }
    }
    ,
    methods: {
        updateSelectedMarkers(markers) {
            this.selectedMarkers = markers
            this.selectedMarkers.forEach(place => {
                this.selectMarker(place)
            })
        },
        setupMap(center) {
            if (!this.map) {
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v12',
                    center: center,
                    zoom: this.zoom
                })

                this.startMarker = new mapboxgl.Marker({
                    color: '#0079c5',
                    draggable: true
                })
                this.endMarker = new mapboxgl.Marker({
                    color: '#15d400', draggable: true
                })

                this.map.once('load', () => {
                    this.$emit(EVENTS.MAP_LOADED,)
                })

            }
        },
        addMarkerLayers() {
            // Default icon layer
            this.map.addLayer({
                id: this.placesData.layerID,
                type: 'symbol',
                source: this.placesData.layerID,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': [
                        'case',
                        // 1) If the feature is "selected"
                        ['==', ['get', 'selected'], true],
                        // Nest another 'case' to pick the correct icon for each plan_state when selected
                        [
                            'case',
                            ['==', ['get', 'plan_state'], FACILITY_PLAN_STATES.FULLY],
                            ECAL_MARKER_FULLY_SELECTED.name,

                            ['==', ['get', 'plan_state'], FACILITY_PLAN_STATES.PARTIALLY],
                            ECAL_MARKER_PARTIALLY_SELECTED.name,

                            ['==', ['get', 'plan_state'], FACILITY_PLAN_STATES.UNPLANNED],
                            ECAL_MARKER_UNPLANNED_SELECTED.name,

                            // Fallback if no condition matched (e.g., if plan_state is missing)
                            'disabled'
                        ],
                        // 2) Otherwise, the feature is "unselected"
                        [
                            'case',
                            ['==', ['get', 'plan_state'], FACILITY_PLAN_STATES.FULLY],
                            ECAL_MARKER_FULLY_UNSELECTED.name,

                            ['==', ['get', 'plan_state'], FACILITY_PLAN_STATES.PARTIALLY],
                            ECAL_MARKER_PARTIALLY_UNSELECTED.name,

                            ['==', ['get', 'plan_state'], FACILITY_PLAN_STATES.UNPLANNED],
                            ECAL_MARKER_UNPLANNED_UNSELECTED.name,

                            // Fallback if no condition matched
                            'disabled'
                        ]
                    ],
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },
            })
        },
        loadPlaces() {
            this.loadImages().then(() => {

                this.map.addSource(this.placesData.layerID, {
                    type: 'geojson',
                    data: this.placesData.data,
                })
                this.addMarkerLayers()
            })
        },
        loadImages() {
            const promises = MAP_MARKERS.map(image => {
                return new Promise((resolve, reject) => {
                    if (MapBoxUtility.isValidImage(image)) {
                        this.map.loadImage(image.url, (error, img) => {
                            if (error) {
                                reject(error)
                                return
                            }
                            this.map.addImage(image.name, img)
                            resolve()
                        })

                    } else {
                        console.error("Image format incorrect", image)
                    }


                })
            })
            return Promise.all(promises)
        },
        canClick() {
            if (!this.startWaypoint) {
                return false
            }
            if (!this.endWaypoint) {
                return false
            }
            return true
        },
        onSelectAuditInDialog(event, auditObj) {
            const eventObj = {
                'facility': this.currentFacility,
                'auditPk': auditObj.pk,
            }

            auditObj['selected'] = event.target.checked ? 1 : 0

            if (event.target.checked) {
                this.updateMarkerIconData()

                this.$emit(EVENTS.SELECT_AUDIT, eventObj)
                return
            }

            // this.currentFacility.properties.selectedAudits.splice(index_, 1)
            this.updateMarkerIconData()
            this.$emit(EVENTS.UNSELECT_AUDIT, eventObj)
        },
        updateMarkerIconData() {
            // 1. Retrieve the current data from your GeoJSON source
            const source = this.map.getSource(this.placesData.layerID);
            const data = source._data; // or get data you stored when setting the source

            // 2. Find the feature in your GeoJSON by its "id" or another unique identifier
            //    and update its plan_state property
            const featureToUpdate = data.features.find(f => f.id === this.currentFacility.id);
            featureToUpdate.properties.plan_state = this.getMarkerIconState(); // or any new state

            // 3. Re-set the data on the source so the map can re-render with updated properties
            source.setData(data);
        },
        getMarkerIconState() {
            const allAudits = this.getAuditsFromFacility(this.currentFacility)

            const curAllAudLen = allAudits.length
            const curSelAudLen = allAudits.filter(audit => audit.selected).length

            if (curSelAudLen === 0) {
                return FACILITY_PLAN_STATES.UNPLANNED
            }
            if (curSelAudLen < curAllAudLen) {
                return FACILITY_PLAN_STATES.PARTIALLY
            }
            if (curSelAudLen === curAllAudLen) {
                return FACILITY_PLAN_STATES.FULLY
            }
        },
        getAuditsFromFacility(facility) {
            if (typeof facility.properties?.audits === 'string') {
                facility.properties.audits = JSON.parse(facility.properties?.audits)
            }
            return facility.properties?.audits
        },
        cancelSelectedAudits() {
            this.showAuditsDialog = false
        },
        addMapEvents() {
            this.map.on('click', this.placesData.layerID, (e) => {

                // if (!this.canClick()) {
                //     alert('Must select start and stop')
                //     return
                // }

                let facility = null
                for (let i = 0; i < this.placesData.data.features.length; ++i) {
                    if (this.placesData.data.features[i].id === e.features[0].id) {
                        facility = this.placesData.data.features[i]
                        break
                    }
                }
                // Get the clicked feature
                if (!facility) {
                    return
                }
                this.showAuditsDialog = true
                this.currentFacility = facility
            })

            this.startMarker.on('dragend', (e) => {
                const newCenter = this.startMarker.getLngLat()
                this.$emit(EVENTS.STARTMARKER_DRAG, [newCenter.lng, newCenter.lat])
            })

            this.endMarker.on('dragend', () => {
                const newCenter = this.endMarker.getLngLat()
                this.$emit(EVENTS.ENDMARKER_DRAG, [newCenter.lng, newCenter.lat])
            })

            this.map.on('mouseenter', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer'
                this.addPopup(e)
            })
            this.map.on('touchstart', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer'
                this.addPopup(e)
            })

            this.map.on('mouseleave', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = ''
                this.popup.remove()
            })
            this.map.on('touchend', this.placesData.layerID, () => {
                this.map.getCanvas().style.cursor = ''
                this.popup.remove()
            })
        },
        removeMarker(place) {
            if (!place) {
                return
            }

            if (this.routeGeoJson) {
                const entries = Object.keys(this.routeGeoJson).length
                if (entries <= 2) {
                    this.toaster.addMessage('Need at least 2 locations, can\'t remove location')
                    return
                }
            }
            // Remove the place by filtering it out of the selectedMarkers array
            let newLocations = this.selectedMarkers.filter(marker => marker?.id !== place?.id)

            this.map.setFeatureState(
                {source: this.placesData.layerID, id: place.id},
                {selected: false}
            )
            this.selectedMarkers = [...newLocations]
            this.$emit(EVENTS.SELECT_LOCATION, newLocations)
        },
        toggleMarker(place) {
            if (!place) {
                return
            }
            // Convert the selectedMarkers array into a Set of ids for quick look-up
            const selectedMarkerIds = new Set(this.selectedMarkers.map(marker => marker.id))
            if (selectedMarkerIds.has(place.id)) {
                this.removeMarker(place)
                return
            }
            this.addMarker(place)
        },
        selectMarker(place) {
            place['selected'] = true
            this.setSelectedStateOnDataFeature(place, true)
        },
        unselectMarker(place) {
            place['selected'] = false
            this.setSelectedStateOnDataFeature(place, false)
        },
        setSelectedStateOnDataFeature(place, state) {
            const source = this.map.getSource(this.placesData.layerID);
            const data = source._data;
            const featureToUpdate = data.features.find(f => f.id === place.id);
            featureToUpdate.properties['selected'] = state;

            source.setData(data);
        },
        calculateCoordinates(coordinates, lng) {
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            const PRESSED_ICON_INDEX = 0
            const DEGREES_ONE_EIGHTY = 180
            const DEGREES_THREE_SIXTY = 360
            const currentLng = coordinates[PRESSED_ICON_INDEX]
            if (Math.abs(lng - currentLng) > DEGREES_ONE_EIGHTY) {
                coordinates[PRESSED_ICON_INDEX] += lng > currentLng ? DEGREES_THREE_SIXTY : -DEGREES_THREE_SIXTY
            }
        },
        setRoute() {
            // if the route already exists on the map, we'll reset it using setData
            if (this.map.getSource('route') && this.routeGeoJson) {
                this.map.getSource('route').setData(this.routeGeoJson)
                return
            }
            // otherwise, we'll make a new request
            this.map.addLayer({
                id: 'route',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: this.routeGeoJson
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#3887be',
                    'line-width': 5,
                    'line-opacity': 0.75
                }
            })
        },
        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(resolve, reject)
                } else {
                    reject(new Error('Geolocation is not supported by this browser.'))
                }
            })
        },
        handlePlaceHover(e) {
            this.addPopup(feature, coordinates)
            // Add handleRoute here when routing is ready
        },
        addPopup(e) {
            const PRESSED_ICON_INDEX = 0
            const feature = e.features[PRESSED_ICON_INDEX]
            const lng = e.lngLat.lng

            const coordinates = feature.geometry.coordinates.slice()

            this.calculateCoordinates(coordinates, lng)

            const props = feature.properties
            const popupContent = document.createElement('div')

            const tempApp = Vue.createApp({
                render() {
                    return Vue.h(NMapboxCreateRoutePopup, {...props})
                }
            })

            tempApp.component('n-mapbox-create-route-popup', NMapboxCreateRoutePopup)
            tempApp.config.compilerOptions.delimiters = ['[[[', ']]]']
            tempApp.mount(popupContent)

            this.popup
                .setLngLat(coordinates)
                .setDOMContent(popupContent)
                .addTo(this.map)
        },
        filterIn(isChecked, propertyValue) {
            const property = 'planState'

            // Update planState filter
            this.filters[property] = isChecked
                ? [...this.filters[property], propertyValue]
                : this.filters[property].filter(type => type !== propertyValue)

            // Reapply the composite filter
            this.applyFilters()
        },
        updateFromDate(value) {
            this.filters.deadline_date.fromDate = value
            this.applyFilters()
        },
        updateToDate(value) {
            this.filters.deadline_date.toDate = value
            this.applyFilters()
        },
        getLocationsByDateFilter() {
            const resultKeys = new Set() // Using a Set to ensure no duplicate keys

            const fromDateObj = this.filters.deadline_date.fromDate ? new Date(this.filters.deadline_date.fromDate) : null
            const toDateObj = this.filters.deadline_date.toDate ? new Date(this.filters.deadline_date.toDate) : null
            const dateFilterLocations = this.placesData.data.dateFilterLocations
            for (const [key, dates] of Object.entries(dateFilterLocations)) {
                for (const dateStr of dates) {
                    const dateObj = new Date(dateStr)

                    // Check if date satisfies the "from" and "to" conditions
                    const isAfterFromDate = fromDateObj ? dateObj >= fromDateObj : true
                    const isBeforeToDate = toDateObj ? dateObj <= toDateObj : true

                    if (isAfterFromDate && isBeforeToDate) {
                        resultKeys.add(key) // Add key if date is within range
                        break // Stop checking further dates for this key
                    }
                }
            }

            return Array.from(resultKeys)
        },
        applyFilters() {
            const filterConditions = ['all']

            if (this.filters.planState.length > 0) {
                filterConditions.push(['in', ['get', 'plan_state'], ['literal', [...this.filters.planState]]])
            }


            if (this.filters.deadline_date.fromDate || this.filters.deadline_date.toDate) {
                const filteredIds = this.getLocationsByDateFilter()
                // This will include only those features whose 'id' property is in filteredIds.
                filterConditions.push(['in', ['id'], filteredIds.join(',')])
            }


            const compositeFilter = filterConditions.length > 1 ? filterConditions : null

            const finalFilter = compositeFilter
                ? [
                    'any',
                    ['==', ['get', 'selected'], true],
                    compositeFilter
                ]
                : null

            this.map.setFilter(this.placesData.layerID, finalFilter)

        }
    },
    mounted() {
        this.getUserLocation()
            .then(position => {
                const userCenter = [position.coords.longitude, position.coords.latitude]
                this.setupMap(userCenter)
            })
            .catch(error => {
                console.error('Error getting user location:', error)
                //TODO
                const swedenCenter = [18.643501, 60.128161]
                this.setupMap(swedenCenter)
            })
    },
    template: `
    <div class="n-flex n-col gap-m" style="padding-top: 16px">
        <div class="n-flex gap-s">
            <n-date-input range @change-from="updateFromDate" @change-to="updateToDate"></n-date-input>
            <n-checkbox :disabled="isLoading" style="margin-right: 8px" @change="filterIn($event, FACILITY_PLAN_STATES.UNPLANNED)" label="Unplanned"></n-checkbox>
            <n-checkbox :disabled="isLoading" style="margin-right: 8px;" @change="filterIn($event, FACILITY_PLAN_STATES.PARTIALLY)" label="Partially planned"></n-checkbox>
            <n-checkbox :disabled="isLoading" style="margin-right: 8px;" @change="filterIn($event, FACILITY_PLAN_STATES.FULLY)" label="Fully planned"></n-checkbox>
        </div>
        
        <div id='map' style='width: 100%; height: auto; flex: 1; border-radius: 5px; border: 2px solid #55b8ff'>
            <div v-if="isLoading" class="n-skeleton" style="background-color: rgba(245,245,245,0.06) !important">
            <span class="n-loader" ></span>
            </div>
        </div>
        <n-dialog :is-open="showAuditsDialog" :actions="false"
                  @confirm="showAuditsDialog = false"
                  @cancel="showAuditsDialog = false"
                  @close="showAuditsDialog = false"
        >
            <h2>Select audit</h2>
            <p>Select one or more audits to add to your route</p>

            <div class="n-flex n-col gap-m padding-s" style="border: 1px solid #e1edff; border-radius:4px;" 
                    v-for="audit in getAuditsFromFacility(currentFacility)"
                    :key="audit.pk">
                    <div v-if="audit.locked"
                            class="n-flex wrap gap-xs" style="padding: 4px; border-radius: 4px; border: 1px solid #ffa600; background-color: rgba(255,213,0,0.07)">
                        <i style="font-size: 10px; width:fit-content">[[[auditAlreadyPlannedText]]]:</i>
                        <a style="font-size: 10px;" :href="audit.plan_work_order_link" target="_blank" style="width: fit-content"> [[[audit.route_name]]]</a>
                    </div>
                    <div class="n-flex gap-m">
                        <input  
                                type="checkbox"
                                @change="onSelectAuditInDialog($event, audit)"
                                :disabled="audit.locked"
                                :checked="audit.selected" 
                                :id="'audit-checkbox-' + audit.pk" :name="'audit-checkbox-' + audit.pk">
                        <label :for="'audit-checkbox-' + audit.pk"  class="n-flex n-col gap-xs padding-s" style="cursor: pointer">    
                            <div class="n-flex gap-s" style="pointer-events: none;">
                                <label style="font-weight: bold" class="n-clamp-text">Audit targets:</label> 
                                <p> [[[audit.audit_target_count]]]</p>
                            </div>
                            <div class="n-flex gap-s"  style="pointer-events: none;">
                                <label style="font-weight: bold" class="n-clamp-text">Deadline Date:</label> 
                                <p> [[[audit.deadline_date]]]</p>
                            </div>
                               <div class="n-flex gap-s"  style="pointer-events: none;">
                                <label style="font-weight: bold" class="n-clamp-text">Mean cal. time:</label> 
                                <p> [[[audit.mean_time]]]</p>
                            </div>
                        </label>
                    </div>
            </div>
            <n-button full-width small text="Close" @click="showAuditsDialog = false"></n-button>
        </n-dialog>
    </div>
    
    `,
}

