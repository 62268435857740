import NMapboxPopup from "./n-mapbox-popup.js";
import NButton from "../buttons/n-button.js";
import NCheckbox from "../inputs/n-checkbox.js";
import NMapboxGeocoder from "./n-mapbox-geocoder.js";
import NDateInput from "../inputs/n-date-input.js";


class ROUTING_PROFILES {
}

ROUTING_PROFILES.DRIVING_TRAFFIC = 'mapbox/driving-traffic'
ROUTING_PROFILES.DRIVING = 'mapbox/driving'
ROUTING_PROFILES.CYCLING = 'mapbox/cycling'
ROUTING_PROFILES.WALKING = 'mapbox/walking'

const ZOOM_LEVEL_DEFAULT = 5
const ZOOM_LEVEL_CLOSE = 8

class RADIUS {
}

RADIUS.SMALL = 20
RADIUS.MEDIUM = 30
RADIUS.LARGE = 40

class THRESHOLD {
}

THRESHOLD.FIRST = 10
THRESHOLD.SECOND = 50

class EVENTS {
}

EVENTS.SELECT_LOCATION = 'on-select-location'
EVENTS.ADD_LOCATION = 'on-add-location'
EVENTS.ON_SEARCH = 'on-search'
EVENTS.ON_MAP_LOAD = 'on-map-load'

const MAPBOX_CLUSTER_PAINT_STYLE = {
    'circle-color':
        [
            'step',
            ['get', 'point_count'],
            '#19A08C',
            THRESHOLD.FIRST,
            '#28C882',
            THRESHOLD.SECOND,
            '#A0FFBE'
        ],
    'circle-radius': [
        'step',
        ['get', 'point_count'],
        RADIUS.SMALL,
        THRESHOLD.FIRST,
        RADIUS.MEDIUM,
        THRESHOLD.SECOND,
        RADIUS.LARGE,
    ],
}

const MAPBOX_CLUSTER_TEXT_LAYOUT = {
    'text-field': '{point_count_abbreviated}',
    'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
    'text-size': 16
}
const MAPBOX_CLUSTER_TEXT_PAINT = {
    'text-color': '#ffffff',
    'text-halo-color': '#000000',
    'text-halo-width': 1
}

export default {
    inject: ['toaster'],
    props: {
        draggable: Boolean,
        isLoading: Boolean,
        placesData: Object,
        isDisabled: {
            default:
                false,
            type:
            Boolean,
        },
        startWaypoint: Object,
        endWaypoint: Object,
        center: Array,
        routeGeoJson: Object,
    },
    emits: Object.values(EVENTS),
    components:
        {
            'n-date-input': NDateInput,
            'n-mapbox-popup': NMapboxPopup,
            'n-button': NButton,
            'n-checkbox': NCheckbox,
            'n-mapbox-geocoder': NMapboxGeocoder,
        }
    ,
    watch: {
        startWaypoint: {
            handler(center) {
                if (this.map && center) {
                    this.startMarker.setLngLat(center).addTo(this.map);
                }
            },
            deep: true
        },
        endWaypoint: {
            handler(center) {
                if (this.map && center) {
                    this.endMarker.setLngLat(center).addTo(this.map);
                }
            },
            deep: true
        },
        placesData: {
            handler(placeData) {
                if (this.map) {
                    this.loadPlaces()
                    this.addMapEvents()

                }
            },
            deep: true
        },
        routeGeoJson: {
            handler(routeGeoJson) {
                this.setRoute();
            },
            deep: true
        },
    }
    ,
    data() {
        return {
            map: null,
            directions: null,
            selectedMarkers: [],
            routeData: {},
            zoom: ZOOM_LEVEL_DEFAULT,
            stops: [],
            popup: null,
            mapboxSearchField: null,
            searchMarker: null,
            startMarker: null,
            endMarker: null,
            hoveredMarker: null,

            geocoderFrom: null,
            geocoderTo: null,
            filters: {
                'stopType': [],
                'dueDate': {
                    'fromDate': null,
                    'toDate': null,
                },
            },
            fromDateValue: null,
            toDateValue: null,
        };
    }
    ,
    methods: {
        setupMap(center) {
            if (!this.map) {
                this.map = new mapboxgl.Map({
                    container: 'map',
                    style: 'mapbox://styles/mapbox/streets-v12',
                    center: center,
                    zoom: this.zoom
                });

                this.startMarker = new mapboxgl.Marker({
                    color: '#0079c5',
                    draggable: this.draggable
                })
                this.endMarker = new mapboxgl.Marker({
                    color: '#15d400', draggable: this.draggable
                })

                this.map.once('load', () => {
                    this.$emit(EVENTS.ON_MAP_LOAD)
                    this.popup = new mapboxgl.Popup({
                        className: 'n-map-popup', offset: 25, closeButton: false,
                    });
                    this.map.resize()
                })
            }
        },
        addMarkerLayers() {
            this.map.addLayer({
                id: this.placesData.layerID,
                type: 'symbol',
                source: this.placesData.layerID,
                filter: ['!', ['has', 'point_count']],
                layout: {
                    'icon-image': ['get', 'icon'],
                    'icon-size': 1,
                    'icon-allow-overlap': true,
                    'icon-ignore-placement': true
                },
            });
        },
        loadPlaces() {
            this.loadImages().then(() => {
                this.map.addSource(this.placesData.layerID, {
                    type: 'geojson',
                    data: this.placesData.data,
                });
                this.addMarkerLayers();
            })
        },
        loadImages() {
            return new Promise((resolve, reject) => {
                this.map.loadImage(ECAL_MARKER_UNPLANNED_SELECTED.url, (error, img) => {
                    if (error) {
                        reject(error);
                        return
                    }
                    this.map.addImage(ECAL_MARKER_UNPLANNED_SELECTED.name, img);
                    resolve();
                });
            });
        },
        addMapEvents() {

            this.map.on('click', this.placesData.layerID, (e) => {
// TODO: needed?
            });


            this.map.on('mouseenter', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)

                const rowId = `facility_${e.features[0].id}`;
                this.hoveredMarker = document.getElementById(rowId);
                if (this.hoveredMarker) {
                    this.hoveredMarker.classList.add('highlight');
                }
            });
            this.map.on('touchstart', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = 'pointer';
                this.addPopup(e)
            });

            this.map.on('mouseleave', this.placesData.layerID, (e) => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove()
                if (this.hoveredMarker) {
                    this.hoveredMarker.classList.remove('highlight')
                }
            });
            this.map.on('touchend', this.placesData.layerID, () => {
                this.map.getCanvas().style.cursor = '';
                this.popup.remove();
            });
        },
        calculateCoordinates(coordinates, lng) {
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            const PRESSED_ICON_INDEX = 0
            const DEGREES_ONE_EIGHTY = 180
            const DEGREES_THREE_SIXTY = 360
            const currentLng = coordinates[PRESSED_ICON_INDEX];
            if (Math.abs(lng - currentLng) > DEGREES_ONE_EIGHTY) {
                coordinates[PRESSED_ICON_INDEX] += lng > currentLng ? DEGREES_THREE_SIXTY : -DEGREES_THREE_SIXTY;
            }
        },
        setRoute() {
            // if the route already exists on the map, we'll reset it using setData
            if (this.map.getSource('route') && this.routeGeoJson) {
                this.map.getSource('route').setData(this.routeGeoJson);
                return
            }
            // otherwise, we'll make a new request
            this.map.addLayer({
                id: 'route',
                type: 'line',
                source: {
                    type: 'geojson',
                    data: this.routeGeoJson
                },
                layout: {
                    'line-join': 'round',
                    'line-cap': 'round'
                },
                paint: {
                    'line-color': '#3887be',
                    'line-width': 5,
                    'line-opacity': 0.75
                }
            })

            const center = this.routeGeoJson.geometry.coordinates[0]
            this.map.flyTo({
                center: center
            });
        },
        getUserLocation() {
            return new Promise((resolve, reject) => {
                if (navigator.geolocation) {
                    navigator.geolocation.getCurrentPosition(resolve, reject);
                } else {
                    reject(new Error('Geolocation is not supported by this browser.'));
                }
            });
        },
        handlePlaceHover(e) {
            this.addPopup(feature, coordinates)
            // Add handleRoute here when routing is ready
        },
        addPopup(e) {
            const PRESSED_ICON_INDEX = 0
            const feature = e.features[PRESSED_ICON_INDEX];
            const lng = e.lngLat.lng;

            const coordinates = feature.geometry.coordinates.slice();

            this.calculateCoordinates(coordinates, lng);

            const props = feature.properties;
            const popupContent = document.createElement('div');

            const tempApp = Vue.createApp({
                render() {
                    return Vue.h(NMapboxPopup, {...props});
                }
            });

            tempApp.component('n-mapbox-popup', NMapboxPopup);
            tempApp.config.compilerOptions.delimiters = ["[[[", "]]]"]
            tempApp.mount(popupContent);

            this.popup
                .setLngLat(coordinates)
                .setDOMContent(popupContent)
                .addTo(this.map)
        },
        filterIn(isChecked, propertyValue) {
            const property = 'stopType';

            // Update stopType filter
            this.filters[property] = isChecked
                ? [...this.filters[property], propertyValue]
                : this.filters[property].filter(type => type !== propertyValue);

            // Reapply the composite filter
            this.applyFilters();
        },
        updateFromDate(value) {
            this.filters.dueDate.fromDate = value;
            this.applyFilters();
        },
        updateToDate(value) {
            this.filters.dueDate.toDate = value;
            this.applyFilters();
        },
        applyFilters() {
            const filterConditions = ['all'];

            if (this.filters.stopType.length > 0) {
                filterConditions.push(['in', ['get', 'stopType'], ['literal', [...this.filters.stopType]]]);
            }

            if (this.filters.dueDate.fromDate) {
                filterConditions.push(['>=', ['get', 'dueDate'], this.filters.dueDate.fromDate]);
            }

            if (this.filters.dueDate.toDate) {
                filterConditions.push(['<=', ['get', 'dueDate'], this.filters.dueDate.toDate]);
            }

            const compositeFilter = filterConditions.length > 1 ? filterConditions : null;
            this.map.setFilter(this.placesData.layerID, compositeFilter);
        }
    },
    mounted() {
        this.getUserLocation()
            .then(position => {
                const userCenter = [position.coords.longitude, position.coords.latitude];
                this.setupMap(userCenter);
            })
            .catch(error => {
                console.error('Error getting user location:', error);
                //TODO
                const swedenCenter = [18.643501, 60.128161];
                this.setupMap(swedenCenter);
            });
    },
    template: `

    <div class="n-flex n-col gap-m" style="padding-top: 16px">
<!--        <div class="n-flex gap-xs">-->
<!--            <n-date-input range @change-from="updateFromDate" @change-to="updateToDate"></n-date-input>-->
<!--            <n-checkbox @change="filterIn($event, 'E-cal')" label="E-cal"></n-checkbox>-->
<!--            <n-checkbox @change="filterIn($event, 'Sermi')" label="Sermi"></n-checkbox>-->
<!--        </div>-->
        
        <div id='map' style='width: 100%; height: auto; flex: 1; border-radius: 5px; border: 2px solid #55b8ff'>
            <div v-if="isLoading" class="n-skeleton">
            <span class="n-loader" ></span>
            </div>
        </div>
    </div>
    `,
}
;
