export default {
    props: {
        name: String,
        address: String,
        customer: String,
        deadline: String,
        auditTargets: Number,
    },
    data() {
        return {
            employeesText: gettext('Employees'),
        };
    },
    methods: {},
    created() {
    },
    mounted() {
    },
    template: `
     <div class="n-flex n-col gap-s">
            <div class="n-flex gap-m align-center" >
                <i class="fa-regular fa-car-wrench"></i>
                <h3>
                [[[name]]]
                </h3>
            </div>
            <div class="n-flex n-col gap-xs padding-xs"  style="border-radius: 4px; background-color: #e1edff">
                <div v-if="auditTargets" class="n-flex gap-s" >
                        <label style="font-weight: bold">Audit targets:</label> 
                        <p> [[[auditTargets]]]</p>
                </div>
                <div v-if="address"  class="n-flex gap-s" >
                        <label style="font-weight: bold" >Address:</label> 
                        <p> [[[address]]]</p>
                </div>
                <div v-if="customer" class="n-flex gap-s" >
                        <label style="font-weight: bold" >Customer:</label> 
                        <p> [[[customer]]]</p>
                </div>
                 <div v-if="deadline" class="n-flex gap-s" >
                        <label style="font-weight: bold" >Deadline:</label> 
                        <p> [[[deadline]]]</p>
                </div>
            </div>
        </div>
    `,
};