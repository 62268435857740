import NCheckbox from "../inputs/n-checkbox.js";

export default {
    components: {
        "n-checkbox": NCheckbox,
    },
    props: {
        name: String,
        address: String,
        audits: String, // Keys: audit_target_count, deadline_date
        dueDate: String,
        auditTargets: Number,
    },
    data() {
        return {
            employeesText: gettext('Employees'),
        };
    },
    methods: {},
    created() {
    },
    mounted() {
    },
    template: `
    <div class="n-flex n-col gap-s">
        <div class="n-flex gap-m align-center" >
            <i class="fa-regular fa-car-wrench"></i>
            <h3 class="n-clamp-text">
            [[[name]]]
            </h3>
        </div>
        <div class="n-flex n-col gap-xs"  >
            <div v-if="JSON.parse(this.audits)?.length === 0" class="n-flex gap-s" style="border-radius: 4px; background-color: #ffe1e1">
                    <label style="font-weight: bold" class="n-clamp-text">There are no available audits</label> 
            </div>
            <div v-if="JSON.parse(this.audits)?.length === 1" class="n-flex gap-s" style="border-radius: 4px; background-color: #e1edff">
                    <label style="font-weight: bold" class="n-clamp-text">Audit targets:</label> 
                    <p> [[[JSON.parse(this.audits)[0].audit_target_count]]]</p>
            </div>
            <div v-if="JSON.parse(this.audits)?.length === 1" class="n-flex gap-s" style="border-radius: 4px; background-color: #e1edff">
                    <label style="font-weight: bold" class="n-clamp-text">Deadline date:</label> 
                    <p> [[[JSON.parse(this.audits)[0].deadline_date]]]</p>
            </div>
            <div v-if="JSON.parse(this.audits)?.length > 1" class="n-flex gap-s padding-xs" style="border-radius: 4px; background-color: #e1edff">
                <label style="font-weight: bold" class="n-clamp-text">Audits:</label> 
                <p> [[[JSON.parse(this.audits)?.length]]]</p>
            </div>
        </div>
    </div>
    `,
};