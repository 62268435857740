import NButton from "./buttons/n-button.js";

class EVENTS {
}

EVENTS.CANCEL = 'cancel'
EVENTS.CONFIRM = 'confirm'
EVENTS.OPEN = 'open'
EVENTS.CLOSE = 'close'

export default {
    inject: ['current_device'],
    watch: {
        isOpen(open, oldValue) {
            if (open) {
                this.openDialog()
                return
            }
            this.closeDialog()
        }
    },
    components: {
        'n-button': NButton,
    },
    props: {
        skipCallback: Boolean,
        isLoading: Boolean,
        actions: Boolean,
        title: String,
        isOpen: Boolean,
        description: String,
        confirmString: {
            default: gettext('Confirm'),
            type: String,
        },
        cancelString: {
            type: String,
            default: gettext('Cancel')
        },
        openDialogString: {
            type: String,
            default: gettext('Open')
        },
    },
    emits: Object.values(EVENTS),
    data() {
        return {}
    },
    methods: {
        openDialog() {
            const dialogRef = this.$refs.nDialog;
            if (dialogRef) {
                dialogRef.showModal();
                dialogRef.classList.remove('custom-backdrop');
            }
        },
        closeDialog() {
            const dialogRef = this.$refs.nDialog;
            dialogRef && dialogRef.close();
            this.$emit(EVENTS.CLOSE,)
        },
        confirm(e) {
            e.preventDefault()
            const dialogRef = this.$refs.nDialog;
            if (!dialogRef) return
            const event = {close_cb: this.closeDialogCallback}
            this.$emit(EVENTS.CONFIRM, event)

            // Perform confirm action
        },
        cancel(e) {
            e.preventDefault()
            const dialogRef = this.$refs.nDialog;
            if (!dialogRef) return
            const event = {close_cb: this.closeDialogCallback}
            this.$emit(EVENTS.CANCEL, event)

        },
        closeDialogCallback() {
            // Sometimes we want to wait for response before closing the dialog.
            // Therefore a simple callback is implemented to close the modal.
            const dialogRef = this.$refs.nDialog;
            dialogRef && dialogRef.close()
        },
    },
    created() {

    },
    mounted() {
        // catches the "esc" click
        this.$refs.nDialog.addEventListener("close", (e) => {
            this.closeDialog(e)
        });
    },
    template: `
        <dialog ref="nDialog" class="n-grid gap-xl n-dialog" >
            <div class="n-grid gap-s " style="min-height: 40px;">
                <slot>
                    <h2 v-if="title">[[[title]]]</h2>
                    <p v-if="description">[[[description]]]</p>
                </slot>
            </div>
            <div v-if="actions" class="n-grid grid-cols-2 gap-m jus-end" style="margin-top: 1rem">
                <n-button full-width small outlined @click="cancel" :text="cancelString"></n-button>
                <n-button full-width small @click="confirm" :text="confirmString" :is-loading="isLoading"></n-button>
            </div>
        </dialog>
        `,
}
